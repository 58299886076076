import React from 'react';
import TextTruncate from 'react-text-truncate';
import Button from 'react-bootstrap/Button';
import cx from 'classnames';
import { CourseIds } from 'constants/courses';
import instagram from 'assets/images/socials/instagram.svg';
import facebook from 'assets/images/socials/facebook.svg';
import { IReview, SocialType } from 'types/review';

interface IProps {
  onExpand: (data: IReview) => void;
  data: IReview;
}

const socialImages = {
  [SocialType.FACEBOOK]: facebook,
  [SocialType.INSTAGRAM]: instagram,
};

const courseIdToClassMap: {
  [id in CourseIds]: 'danger' | 'primary' | 'secondary';
} = {
  [CourseIds.CHESS]: 'secondary',
  [CourseIds.PROGRAMMING]: 'primary',
};

const courseIdToNameMap: {
  [id in CourseIds]: string;
} = {
  [CourseIds.CHESS]: 'Шахматы',
  [CourseIds.PROGRAMMING]: 'Программирование',
};

export const ReviewCard = ({ onExpand, data }: IProps) => {
  const handleExpand = React.useCallback(() => {
    onExpand(data);
  }, [data]);

  return (
    <div className="review-card">
      <div className={cx('review-course', courseIdToClassMap[data.courseId])}>
        {courseIdToNameMap[data.courseId]}
      </div>
      <div className="review-header">
        <img src={data.photo} alt="Фото" className="review-photo" />
        <div className="review-header-content">
          <div className="review-name">
            <span>{data.name}</span>
            <a
              className="review-social"
              href={data.socialLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={socialImages[data.social]} alt="Социальная сеть" />
            </a>
          </div>
          {data.socialTag && (
            <a
              className="review-social-tag"
              href={data.socialLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              {data.socialTag}
            </a>
          )}
        </div>
      </div>
      <TextTruncate
        line={8}
        containerClassName="review-text"
        textTruncateChild={
          <div>
            <Button
              onClick={handleExpand}
              variant="link"
              className="expand-button"
            >
              Читать полностью
            </Button>
          </div>
        }
        text={data.text}
      />
    </div>
  );
};
