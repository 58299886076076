import React from 'react';
import { Form as FormikForm, FormikProps, withFormik } from 'formik';
import { FormInput } from 'components/FormInput';
import { TextareaInput } from 'components/TextareaInput';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { ButtonSpinner } from 'components/ButtonSpinner';
import { createReviewRequest } from 'api/requests/createReviewRequest';
import { apiCallErrorWithMessage, apiCallSuccessWithMessage } from 'api/utils';
import { apiMessages } from 'api/requests/messages';
import Alert from 'react-bootstrap/Alert';
import { IFormValues } from './types';

const FormInner = ({ isSubmitting, status }: FormikProps<IFormValues>) => (
  <FormikForm>
    {status && (
      <Alert variant="danger" className="text-center">
        {status}
      </Alert>
    )}
    <FormInput<keyof IFormValues>
      name="name"
      title="Имя"
      placeholder="Введите ваше имя"
    />
    <FormInput<keyof IFormValues>
      type="email"
      name="email"
      title="Email"
      placeholder="Введите ваш email"
    />
    <TextareaInput<keyof IFormValues>
      rows={10}
      name="text"
      title="Текст"
      placeholder="Введите текст сообщения..."
      maxLength={2000}
    />
    <Button
      disabled={isSubmitting}
      type="submit"
      className="action-btn"
      variant="secondary"
      size="lg"
    >
      {isSubmitting ? <ButtonSpinner /> : `Отправить отзыв`}
    </Button>
  </FormikForm>
);

const withForm = withFormik<never, IFormValues>({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    text: '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    text: Yup.string().required(),
  }),
  handleSubmit: (
    values,
    { setSubmitting, setErrors, setStatus, resetForm },
  ) => {
    createReviewRequest(values)
      .then(
        apiCallSuccessWithMessage(setSubmitting, () => {
          import('sweetalert2').then((Swal) => {
            Swal.default.fire({
              title: 'Отзыв отправлен!',
              text: apiMessages.review.success,
              icon: 'success',
              confirmButtonText: 'ОК',
            });
          });
          resetForm();
        }),
      )
      .catch(apiCallErrorWithMessage(setSubmitting, setErrors, setStatus));
  },
});

export const Form = withForm(FormInner) as React.ComponentType;
