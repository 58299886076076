import React from 'react';
import { ReviewModal } from 'containers/Modals/ReviewModal';
import { IReview } from 'types/review';
import Button from 'react-bootstrap/Button';
import { reviews } from 'constants/reviews';
import { ButtonSpinner } from 'components/ButtonSpinner';
import { ReviewList } from './ReviewList';

export const ReviewListBlock = () => {
  const [reviewSlide, setReviewSlide] = React.useState(0);
  const [slideShiftActive, setSlideShiftActive] = React.useState(false);
  const loadedRef = React.useRef(false);
  const [reviewModal, setReviewModal] = React.useState<{
    isOpen: boolean;
    data?: IReview;
  }>({
    isOpen: false,
    data: undefined,
  });

  const onReviewClose = React.useCallback(() => {
    setReviewModal({ isOpen: false });
  }, []);

  const onReviewOpen = React.useCallback((data) => {
    setReviewModal({ isOpen: true, data });
  }, []);

  const handleLoadButton = React.useCallback(() => {
    if ((reviewSlide + 1) * 4 > reviews.length) {
      setReviewSlide(0);
    } else {
      setReviewSlide(reviewSlide + 1);
    }
    setSlideShiftActive(true);
  }, [reviewSlide]);

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (loadedRef.current && slideShiftActive) {
      setTimeout(() => {
        setSlideShiftActive(false);
      }, 500);
    } else {
      loadedRef.current = true;
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [slideShiftActive]);

  return (
    <section className="review-block container">
      <ReviewList
        hidden={slideShiftActive}
        slide={reviewSlide}
        onReviewOpen={onReviewOpen}
      />
      <Button
        disabled={slideShiftActive}
        onClick={handleLoadButton}
        size="lg"
        className="load-button"
      >
        {slideShiftActive ? <ButtonSpinner /> : `Ещё отзывы`}
      </Button>
      {reviewModal.data && (
        <ReviewModal
          isOpen={reviewModal.isOpen}
          onClose={onReviewClose}
          data={reviewModal.data}
        />
      )}
    </section>
  );
};
