import React from 'react';
import Button from 'react-bootstrap/Button';
import { BackgroundImg } from './BackgroundImg';

export const MainBlock = () => {
  const onClick = React.useCallback(() => {
    (document.querySelector('.form-block') as HTMLDivElement).scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  return (
    <section className="main-block container">
      <div className="background-top" />
      <div className="main-proposition">
        <h1 className="title">Отзывы учеников и родителей</h1>
        <div className="subtitle">
          Спасибо за то, что вы с нами! Успехи наших детей и ваши благодарные
          письма - наше вдохновение!
        </div>
        <Button
          onClick={onClick}
          variant="secondary"
          size="lg"
          className="action-btn"
        >
          Оставить отзыв
        </Button>
      </div>
      <BackgroundImg />
    </section>
  );
};
