import { API_PATHS, createAxiosInstance } from '../constants';
import { IError, ISuccess } from '../types';
import { IReviewData } from './types';

export const createReviewRequest = async (
  review: IReviewData,
): Promise<IError | ISuccess> => {
  const axiosInstance = createAxiosInstance();
  await axiosInstance.post(API_PATHS.landing.reviews, review);
  return { success: true };
};
