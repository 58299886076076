import React from 'react';
import { reviews } from 'constants/reviews';
import { IReview } from 'types/review';
import { CSSTransition } from 'react-transition-group';
import { ReviewCard } from './ReviewCard';

interface IProps {
  onReviewOpen: (data: IReview) => void;
  slide: number;
  hidden: boolean;
}

export const ReviewList = ({ onReviewOpen, slide, hidden }: IProps) => {
  const fromIndex = slide * 4;
  const toIndex = fromIndex + 4;
  return (
    <CSSTransition appear in={!hidden} timeout={500}>
      <div className="review-list row">
        {reviews.slice(fromIndex, toIndex).map((review, index) => (
          <div key={index} className="review-item col-12 col-sm-10 col-lg-6">
            <ReviewCard onExpand={onReviewOpen} data={review} />
          </div>
        ))}
      </div>
    </CSSTransition>
  );
};
