import React from 'react';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';
import { BaseEmoji } from 'emoji-mart';
import cx from 'classnames';
import { EmojiPicker } from 'components/EmojiPicker';
import TextareaAutosize from 'react-textarea-autosize';
import './styles.scss';

interface IProps<Name extends string> {
  name: Name;
  title: string;
  maxLength: number;
  placeholder?: string;
  required?: boolean;
  className?: string;
  rows?: number;
}

export const TextareaInput = <T extends string>({
  name,
  title,
  placeholder,
  required,
  className,
  rows,
  maxLength,
}: IProps<T>) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [field, meta] = useField(name);
  const isInvalid = meta.touched && !!meta.error;
  const onEmojiSelect = React.useCallback((e: BaseEmoji) => {
    if (inputRef.current) {
      inputRef.current.value += e.native;
      field.onChange({ target: { value: inputRef.current.value, name } });
    }
  }, []);

  React.useEffect(() => {
    if (field.value === '' && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [field.value]);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('input', field.onChange);
    }
  }, [inputRef.current]);
  const length = field.value.length;

  return (
    <Form.Group>
      <div className={cx('form-control-wrapper', { 'is-invalid': isInvalid })}>
        <TextareaAutosize
          name={name}
          className={cx('form-control', { 'is-invalid': isInvalid }, className)}
          placeholder={placeholder}
          minRows={rows}
          ref={inputRef}
          onBlur={field.onBlur}
          required={required}
          maxLength={maxLength}
          title={title}
        />
        <EmojiPicker onSelect={onEmojiSelect} />
        <div
          aria-hidden="true"
          className={cx('limit', { 'is-invalid': length === maxLength })}
        >{`${length}/${maxLength}`}</div>
      </div>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};
