import React from 'react';
import { SEO } from 'components/SEO';
import { MainBlock } from './MainBlock';
import { ReviewListBlock } from './ReviewListBlock';
import { FormBlock } from './FormBlock';
import './styles.scss';

const Reviews = () => (
  <main className="page reviews-page">
    <SEO
      title="Отзывы"
      description="На этой странице отображены отзывы детей и их родителей о нашей онлайн-школе программирования, шахмат и математике. Только самые хорошие впечатления и высокие оценки о наших курсах."
    />
    <MainBlock />
    <ReviewListBlock />
    <FormBlock />
  </main>
);

export default Reviews;
